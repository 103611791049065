import { ref } from "vue";
import RegistrationProcessDictionaryProxy from '../../../../proxies/registrationProcessDictionaryProxy';

export default function useDocRefSTPRFNameDirectoryRef() {
    const value = ref([]);

    async function fetch() {
        value.value = await RegistrationProcessDictionaryProxy.getDocRefSTPRFName();
    }

    fetch();

    return {
        value
    }
}