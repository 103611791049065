<template>
  <fieldset>
    <DocFields
      :modelValue="data"
      :document-type-id="documentTypeId"
      :document-reg-num="documentRegNum"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #additional-fields>
        <UniversalSelectField
          :options="stprfNames"
          :isMultiSelect="true"
          :modelValue="data.stprfName"
          @update:modelValue="update('stprfName', $event, v$.stprfName)"
          :errors="v$.stprfName.$errors"
          data-qa="schemaName"
        >
          <label class="label label--required">Наименование схемы</label>
        </UniversalSelectField>
      </template>
    </DocFields>
  </fieldset>
</template>

<script>
import DocFields from "./DocFields.vue";

import { toRefs } from "vue";
import { helpers, required } from "@vuelidate/validators";
import useValidator from "./validator";
import useDocRefSTPRFNameDirectoryRef from "@/components/RegistrationProcess/RegistrationDocumentFields/hooks/docRefSTPRFNameHook";
import DocSection1 from "@/models/documents/docSection1";
import UniversalSelectField from "@/components/basic/form/select/UniversalSelectField.vue";

export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => new DocSection1(),
    },
    documentTypeId:{
      type: Number,
      required: true
    },
    documentRegNum:{
      type: String,
      required: false
    }
  },
  components: { UniversalSelectField, DocFields },
  setup(props, { emit }) {
    const { value: stprfNames } = useDocRefSTPRFNameDirectoryRef();
    const { modelValue: data } = toRefs(props);

    function update(fieldName, value, field) {
      const newValue = { ...props.modelValue };
      newValue[fieldName] = value;
      if (field) field.$model = value;
      emit("update:modelValue", newValue);
    }

    let rules = {
      stprfName: {
        required: helpers.withMessage("Заполните поле", required),
      },
    };

    const v$ = useValidator(data, rules);

    return { data, update, stprfNames, v$ };
  },
};
</script>